import React, { useState } from 'react'
import './navbar.css';

//import icons
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.svg';

import SignIn from '../signin/SignIn';
import SignUp from '../signup/SignUp';

const Menu = () => (
	<>
		<p><a href='#home'>Home</a></p>
		<p><a href='#wgpt3'>What is GPT?</a></p>
		<p><a href='#possibility'>Open AI</a></p>
		<p><a href='#features'>Case Studies</a></p>
		<p><a href='#blog'>Library</a></p>
	</>
)

const  Navbar = () => {
	const [toggleMenu, setToggleMenu] = useState(false);
	
	const handleSignUp = (formData) => {
		window.location.href = '/';
    console.log(formData);
  };

	return (
		<div className='gpt3__navbar '>
			<div className='gpt3__navbar-links'>
				<div className='gpt3__navbar-links_logo'>
					<img src={logo} alt="logo" />
				</div>
				<div className='gpt3__navbar-links_container'>
					<Menu />
				</div>
			</div>
			<div className='gpt3__navbar-sign'>
				<SignUp onSignUp={handleSignUp} />
				<SignIn onSignIn={handleSignUp} />
			</div>
			<div className='gpt3__navbar-menu'> {/*for mobile menu */}
				{toggleMenu
					? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
					: <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
				}
				{toggleMenu && (
					<div className='gpt3__navbar-menu_container scale-up-center'>
						<div className='gpt3__navbar-menu_container-links'>
							<Menu />
							<div className='gpt3__navbar-menu_container-links-sign'>
								<SignUp onSignUp={handleSignUp} />
								<SignIn onSignIn={handleSignUp} />
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Navbar


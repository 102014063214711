import React from 'react'
import './article.css';

const Article = ({ imgUrl, date, title, link }) => {
	return (
		<div className='gpt3__blog-container_article'>
			<div className='gpt3__blog-container_article-image'>
				<img src={imgUrl} alt='blog ' />
			</div>
			<div className='gpt3__blog-container_article-content'>
				<div>
					<p>{date}</p>
					<h3>{title }</h3>
				</div>
				{/* <p href='https://google.com'>Read Full Article</p> */}
				<a href={link} target="_blank" rel="noopener noreferrer">Read Full Article</a>
			</div>
		</div>
	)
}

export default Article